import { faUser } from "@jewlr/pro-light-svg-icons/faUser"
import { Button } from "@jewlr/storybook/core"
import loadable from "@loadable/component"
import PropTypes from "prop-types"
import React, { Suspense } from "react"

import setDropdown from "areas/header/hoc/set-dropdown"
import { retry } from "helpers/application"

import { NavIcon, MenuTriangle } from "./components"

const AccountDropdown = loadable(
  () => retry(() => import("./account-dropdown")),
  { ssr: false }
)

const AccountIcon = ({ buttonProps, loggedIn, showDropdown }) => (
  <>
    <Button {...buttonProps}>
      <MenuTriangle show={showDropdown}>
        <NavIcon data-cy="header-account-icon-desktop" icon={faUser} />
      </MenuTriangle>
    </Button>
    {typeof window !== "undefined" && (
      <Suspense fallback={<div />}>
        {loggedIn && showDropdown && <AccountDropdown />}
      </Suspense>
    )}
  </>
)

AccountIcon.propTypes = {
  buttonProps: PropTypes.object,
  loggedIn: PropTypes.bool,
  showDropdown: PropTypes.bool,
}

export default setDropdown(AccountIcon)
