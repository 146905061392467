import { Flex } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { Suspense } from "react"
import styled from "styled-components"

const SegmentBox = styled(Flex)`
  align-items: baseline;
  font-weight: 700;
  justify-content: center;
  width: 9px;
`

const Segment = ({ name, segment }) => {
  const digits = segment.split("")

  return digits.map((digit, i) => (
    <SegmentBox key={`${name}_${digit}_${i}`}>
      {typeof window !== "undefined" && (
        <Suspense fallback={<div />}>
          <span>{digit}</span>
        </Suspense>
      )}
    </SegmentBox>
  ))
}

Segment.propTypes = {
  name: PropTypes.string,
  segment: PropTypes.string,
}

export default Segment
