import { faHeart } from "@jewlr/pro-light-svg-icons/faHeart"
import { faSpinner } from "@jewlr/pro-regular-svg-icons/faSpinner"
import { faHeart as solidHeart } from "@jewlr/pro-solid-svg-icons/faHeart"
import { Box } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { NavIcon } from "areas/header/dropdowns/components"

const WishlistIcon = ({ count, loggedIn, wishlistLoading }) => {
  return (
    <Box
      aria-label="My Wish List"
      as={Link}
      data-lc="wishlist-icon"
      display="inline-block"
      to={loggedIn ? "/account/wishlist" : "/wishlist"}
    >
      <NavIcon
        color={wishlistLoading || count === 0 ? "blackPrint" : "wishlist"}
        icon={wishlistLoading ? faSpinner : count > 0 ? solidHeart : faHeart}
        spin={wishlistLoading}
      />
    </Box>
  )
}

WishlistIcon.propTypes = {
  count: PropTypes.number,
  loggedIn: PropTypes.bool,
  wishlistLoading: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    count: state.wishlist.count,
    loggedIn: Object.keys(state.user.jewlrorders).length > 0,
    wishlistLoading: state.wishlist.loading,
  }
}

export default connect(mapStateToProps)(WishlistIcon)
