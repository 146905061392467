import loadable from "@loadable/component"
import PropTypes from "prop-types"
import React, { createContext, useState } from "react"
import { withRouter } from "react-router-dom"

import { retry } from "helpers/application"
import useWindowDimensions from "helpers/window-dimensions"

import HeaderContent from "./header-content"
import HeaderPush from "./header-push"

const AccountMenu = loadable(() =>
  retry(() => import("./menu/mobile/account-menu"))
)
const MobileMenu = loadable(() =>
  retry(() => import("./menu/mobile/main-menu"))
)

export const HeaderMenuContext = createContext()

const Header = ({ location, menu }) => {
  const [sticky, setSticky] = useState(false)
  const { isTablet, isWidescreen } = useWindowDimensions()

  if (
    location.pathname.includes("/checkout") ||
    location.pathname.includes("/payments")
  ) {
    return ""
  }

  return (
    <HeaderMenuContext.Provider value={{ menu }}>
      <HeaderPush />
      <HeaderContent
        location={location}
        setSticky={setSticky}
        sticky={sticky}
      />
      {isTablet && <AccountMenu />}
      {!isWidescreen && <MobileMenu />}
    </HeaderMenuContext.Provider>
  )
}

Header.propTypes = {
  location: PropTypes.object,
  menu: PropTypes.object,
}

export default withRouter(Header)
