import { history } from "app-store-v2/index"
import { isTestMode } from "helpers/application"

const SEARCH_STORAGE = "jewlr4-suggestions"

export const send_search_keyword = function (keyword, href) {
  if (
    !(typeof ga === "undefined") &&
    Object.prototype.hasOwnProperty.call(ga, "loaded") &&
    ga.loaded === true &&
    !isTestMode
  ) {
    let sq = "/search?k=AC-" + keyword
    dataLayer.push({
      customPagePath: sq,
      event: "jewlr-page-view",
    })
  }

  history.push(href)
}

export const getSearchHistory = () => {
  if (typeof localStorage === "undefined") return []

  const savedQueries = localStorage.getItem(SEARCH_STORAGE)
  return (savedQueries ? JSON.parse(savedQueries) : []).slice(0, 3)
}

export const setSearchHistory = (query) => {
  const keyword = query?.trim()
  if (!keyword) return

  if (typeof localStorage === "undefined") return

  const savedQueries = localStorage.getItem(SEARCH_STORAGE)
  let savedSuggestions = (savedQueries ? JSON.parse(savedQueries) : []).filter(
    (suggestion) => suggestion.name !== keyword
  )

  const newQuery = {
    keyword,
    name: keyword,
    query_string: keyword,
    url: `/search?k=${keyword}`,
  }

  savedSuggestions.unshift(newQuery)
  savedSuggestions = savedSuggestions.slice(0, 3)

  localStorage.setItem(SEARCH_STORAGE, JSON.stringify(savedSuggestions))

  if (!isTestMode) {
    dataLayer.push({
      event: "search",
      searchString: query,
    })
  }
}
