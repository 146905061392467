import { Box } from "@jewlr/storybook/core"
import { useHandleOutsideClick } from "@jewlr/storybook/helpers"
import { useHover } from "@react-aria/interactions"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import { connect } from "react-redux"

import {
  deactivateMenu,
  deactivateSubmenu,
  deactivateAccountMenu,
  setExplicitLogin,
} from "app-store-v2/app/actions"
import { fetchCartList, setHeaderDropdown } from "app-store-v2/header/actions"
import { fetchWishList } from "app-store-v2/wishlist/actions"
import { openModal } from "components/modal"

export default function setDropdown(WrappedComponent) {
  const InternalSetDropdown = (props) => {
    const {
      closeDropdown,
      dropdown,
      loggedIn,
      openDropdown,
      showDropdown,
    } = props
    const dropdownRef = useRef()

    const { hoverProps } = useHover({
      onHoverEnd: () => closeDropdown(),
      onHoverStart: () => {
        if (dropdown !== "account" || loggedIn) {
          openDropdown()
        }
      },
    })

    const buttonProps = {
      "aria-label": dropdown,
      onClick: () => (showDropdown ? closeDropdown() : openDropdown()),
      ...((dropdown !== "account" || loggedIn) && {
        "aria-controls": `${dropdown}_dropdown`,
        "aria-expanded": showDropdown,
        "aria-haspopup": true,
      }),
      variant: "text",
    }

    useHandleOutsideClick({
      enabled: showDropdown,
      onOutsideClick: () => closeDropdown(),
      ref: dropdownRef,
    })

    return (
      <Box
        height={{ tablet: "auto" }}
        position={{ tablet: "relative" }}
        ref={dropdownRef}
        {...hoverProps}
      >
        <WrappedComponent buttonProps={buttonProps} {...props} />
      </Box>
    )
  }

  const mapStateToProps = (state, ownProps) => {
    return {
      count: state[ownProps.dropdown]?.count,
      items: state[ownProps.dropdown]?.line_items,
      loggedIn: Object.keys(state.user.jewlrorders).length > 0,
      showAccountMenu: state.menu.showAccountMenu,
      showDropdown: state.header.dropdown === ownProps.dropdown,
      showMenu: state.menu.showMenu,
      wishlistLoaded: state.wishlist.show,
      wishlistLoading: state.wishlist.loading,
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return { dispatch }
  }

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { loggedIn, showMenu, showAccountMenu, wishlistLoaded } = stateProps
    const { dropdown } = ownProps
    const { dispatch } = dispatchProps

    return {
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      closeDropdown: () => dispatch(setHeaderDropdown("")),
      openDropdown: () => {
        if (dropdown !== "account" || loggedIn) {
          if (dropdown === "wishlist" && !wishlistLoaded) {
            dispatch(fetchWishList())
          } else if (dropdown === "cart") {
            dispatch(fetchCartList())
          }
          dispatch(setHeaderDropdown(dropdown))
        } else if (dropdown === "account") {
          dispatch(setExplicitLogin())
          openModal("#authenticate")
        }

        if (showMenu) {
          dispatch(deactivateMenu())
          dispatch(deactivateSubmenu())
        }

        if (showAccountMenu) {
          dispatch(deactivateAccountMenu())
        }
      },
    }
  }

  InternalSetDropdown.propTypes = {
    closeDropdown: PropTypes.func,
    dropdown: PropTypes.string,
    loggedIn: PropTypes.bool,
    openDropdown: PropTypes.func,
    showDropdown: PropTypes.bool,
  }

  const SetDropdown = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(InternalSetDropdown)

  return SetDropdown
}
