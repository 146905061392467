import { Button } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import withHeader from "areas/header/hoc/with-header"

const MenuIcon = styled(Button).attrs({
  variant: "text",
})`
  background-color: ${(props) => props.theme.colors.white};
  height: 20px;
  width: 20px;
  ${(props) => props.theme.mediaQueries.desktop`
    height: 24px;
    width: 24px;
  `}
  ${(props) => props.theme.mediaQueries.large`
    display: none;
  `}
`
const Hamburger = styled.div`
  &&,
  :before,
  :after {
    background-color: ${(props) => props.theme.colors.black};
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    transition: background ease 0.2s, top ease 0.2s 0.2s, transform ease 0.2s;
    width: 20px;
    ${(props) => props.theme.mediaQueries.desktop`
      width: 24px;
    `}
  }
  :before {
    top: -7px;
    ${(props) => props.theme.mediaQueries.desktop`
      top: -8px;
    `}
  }
  :after {
    top: 7px;
    ${(props) => props.theme.mediaQueries.desktop`
      top: 8px;
    `}
  }
  ${(props) =>
    props.close &&
    `
    && {
      background-color: transparent;
    }
    :before, :after {
      top: 0;
      transition: top ease .2s, transform ease .2s .2s;
    }
    :before {
      transform: rotate(45deg);
    }
    :after {
      transform: rotate(-45deg);
    }
  `}
`

const BurgerMenu = ({ showMenu, toggleMenu }) => {
  return (
    <MenuIcon
      aria-controls="mobile-menu"
      aria-expanded={showMenu}
      aria-haspopup="true"
      aria-label="Menu"
      data-cy="header-menu-icon-mobile"
      id="js_menu_toggle"
      ml={{ _: 4, desktop: 8 }}
      onClick={() => toggleMenu(showMenu)}
    >
      <Hamburger close={showMenu} />
    </MenuIcon>
  )
}

BurgerMenu.propTypes = {
  showMenu: PropTypes.bool,
  toggleMenu: PropTypes.func,
}

export default withHeader(BurgerMenu)
