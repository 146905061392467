import { useEffect, useState } from "react"

const useCountdown = (targetDate, showDays = false) => {
  const targetDateTime = new Date(targetDate).getTime()
  const [countdown, setCountdown] = useState(
    targetDateTime - new Date().getTime()
  )

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = targetDateTime - new Date().getTime()

      if (diff > 0) {
        setCountdown(diff)
      } else {
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [targetDateTime])

  const hours = showDays
    ? Math.floor((countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    : Math.floor(countdown / (1000 * 60 * 60))
  const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countdown % (1000 * 60)) / 1000)

  let sectors = [hours, minutes, seconds]
  if (showDays) {
    const days = Math.floor(countdown / (1000 * 60 * 60 * 24))
    sectors.unshift(days)
  }

  return sectors.map((num) =>
    num >= 10 ? String(num) : num > 0 ? `0${num}` : "00"
  )
}

export default useCountdown
