import PropTypes from "prop-types"
import React from "react"
import { withRouter } from "react-router-dom"
import styled from "styled-components"

const PushContainer = styled.div`
  height: 104px;
  opacity: 0;
  ${(props) => props.theme.mediaQueries.large`
    height: 150px;
  `}
  ${(props) =>
    props.inCart &&
    `
    display: block;
    ${props.theme.mediaQueries.tablet`
      display: none;
    `}
  `}
`

const HeaderPush = ({ location }) => {
  const inCart = location.pathname.includes("cart")

  return <PushContainer aria-hidden inCart={inCart} />
}

HeaderPush.propTypes = {
  location: PropTypes.object,
}

export default withRouter(HeaderPush)
