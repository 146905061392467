import { Box, Image } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import {
  displaySavedUserSearchHistory,
  saveUserSearchHistory,
  mayUpdateSearchSuggestions,
} from "app-store-v2/app/actions"
import {
  clearSearchUserInput,
  clearSearchSuggestions,
  fetchSearchSuggestions,
  toggleSearching,
  updateSearchValue,
} from "app-store-v2/search/actions"
import {
  SuggestTitle,
  SuggestionItem,
  Subtitle,
  StyledImage,
} from "areas/header/components"
import { ImageWrapper, ProductName } from "areas/header/jewlr/search/components"
import MatchQuery from "areas/header/jewlr/search/match-query"
import { isTestMode } from "helpers/application"
import useWindowDimensions from "helpers/window-dimensions"

export default function withSearch(WrappedComponent) {
  const InternalWithSearch = (props) => {
    const { setShowSearchBar } = props
    const { isTablet } = useWindowDimensions()

    const handleOnClick = () => {
      if (setShowSearchBar) {
        setShowSearchBar(false)
      }
    }

    const getSuggestionValue = () => {
      return ""
    }

    const renderSuggestion = (suggestion, { query }) => {
      const name = <MatchQuery name={suggestion.name} query={query} />

      if (!isTablet) {
        return (
          <Box
            aria-label={suggestion.name}
            as={Link}
            data-lc="with-search"
            display="block"
            to={suggestion.url}
          >
            <Box>
              <ImageWrapper>
                <Image
                  alt={suggestion.name}
                  height="100px"
                  srcSet={suggestion.image_url}
                  width="100px"
                />
              </ImageWrapper>
              <ProductName>{name}</ProductName>
            </Box>
          </Box>
        )
      }

      return (
        <SuggestionItem
          data-lc="with-search"
          onClick={handleOnClick}
          to={suggestion.url}
        >
          {suggestion.image_url && <StyledImage src={suggestion.image_url} />}

          {suggestion.subtitle ? (
            <Box lineHeight="solid" width="70%">
              <SuggestTitle>{name}</SuggestTitle>
              <Subtitle>{suggestion.subtitle}</Subtitle>
            </Box>
          ) : (
            <SuggestTitle ml={2}>{name}</SuggestTitle>
          )}
        </SuggestionItem>
      )
    }

    return (
      <WrappedComponent
        {...props}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
      />
    )
  }

  const mapStateToProps = (state) => {
    const { searching, suggestions, value } = state.search

    return {
      searching,
      suggestions,
      value,
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      clearSearch: () => {
        dispatch(toggleSearching(false))
        dispatch(clearSearchUserInput())
      },
      clearSearchSuggestions: () => {
        dispatch(clearSearchSuggestions())
      },
      displaySavedSearchHistory: () => {
        dispatch(displaySavedUserSearchHistory())
      },
      onChange: (e) => {
        dispatch(updateSearchValue(e.target.value || ""))
      },
      onSuggestionsClearRequested: () => {
        dispatch(toggleSearching(false))
        dispatch(clearSearchSuggestions())
      },
      onSuggestionsFetchRequested: ({ value }) => {
        const query = (value || "").trim()
        if (query.length > 2) {
          dispatch(toggleSearching(true))
          dispatch(fetchSearchSuggestions(query))
        } else {
          dispatch(toggleSearching(false))
          dispatch(mayUpdateSearchSuggestions([], query))
        }
      },
      saveSearchHistory: (query) => {
        dispatch(saveUserSearchHistory(query))
        if (!isTestMode) {
          dataLayer.push({
            event: "search",
            searchString: query,
          })
        }
      },
      toggleSearching: (searching) => {
        dispatch(toggleSearching(searching))
      },
    }
  }

  InternalWithSearch.propTypes = {
    setShowSearchBar: PropTypes.func,
  }

  const WithSearch = connect(
    mapStateToProps,
    mapDispatchToProps
  )(InternalWithSearch)

  return WithSearch
}
