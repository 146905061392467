import { faSearch } from "@jewlr/pro-light-svg-icons/faSearch"
import { faUser } from "@jewlr/pro-light-svg-icons/faUser"
import { Box, Button, Flex, MediaBox, Image } from "@jewlr/storybook/core"
import Cookies from "js-cookie"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"

import {
  clearSearchUserInput,
  clearSearchSuggestions,
} from "app-store-v2/app/actions"
import CartIcon from "areas/header/dropdowns/cart-icon"
import { NavIcon } from "areas/header/dropdowns/components"
import withHeader from "areas/header/hoc/with-header"
import HeaderSearch from "areas/header/jewlr/search/search"
import WishlistIcon from "areas/header/jewlr/wishlist-icon"

import BurgerMenu from "./burger-menu"

const Header = styled(MediaBox).attrs({
  lessThan: "desktop",
})`
  background-color: white;
  box-shadow: 0 4px 4px 0px rgba(49, 49, 49, 0.15);
  display: flex;
  height: 64px;
  max-height: 64px;
  min-height: 64px;
  position: relative;
  width: 100%;
`

const HeaderIcon = styled(Box)`
  margin-left: 16px;
  padding: 0;
  ${(props) => props.theme.mediaQueries.tablet`
    margin-left: 24px;
  `}
  :first-child {
    margin-left: 0;
  }
`

const MenuRow = styled(Flex)`
  align-items: center;
  height: 64px;
  justify-content: space-between;
  max-height: 64px;
  min-height: 64px;
  padding: 0 16px;
  width: 100%;
`

const FlexRow = styled(Flex)`
  align-items: center;
`

const MobileHeader = ({
  clearSearch,
  location,
  toggleMenu,
  showAccountMenu,
  sticky,
  toggleAccountMenu,
  loggedIn,
}) => {
  const [searching, setSearch] = useState(false)
  const [geo, setGeo] = useState(null)
  const searchInputRef = useRef()
  const searchBtnRef = useRef()
  const restoreSearchFocus = useRef()

  useEffect(() => {
    toggleMenu(true)
    toggleAccountMenu(true)
    setSearch(false)
    clearSearch()
  }, [location.pathname])

  useEffect(() => {
    setGeo(Cookies.get("geo"))
  }, [geo])

  useEffect(() => {
    if (!searching && restoreSearchFocus.current && searchBtnRef.current) {
      searchBtnRef.current.focus()
      restoreSearchFocus.current = false
    }
  }, [searching, searchBtnRef.current])

  const toggleSearchBar = (e) => {
    e.preventDefault()
    setSearch(!searching)
    toggleMenu(true)
    toggleAccountMenu(true)
  }

  const handleCloseSearch = () => {
    restoreSearchFocus.current = true
    setSearch(false)
  }

  return (
    <Header sticky={sticky}>
      {searching ? (
        <FlexRow>
          <HeaderSearch
            handleCloseSearch={handleCloseSearch}
            isMobile
            searchInputRef={searchInputRef}
          />
        </FlexRow>
      ) : (
        <MenuRow>
          <Link data-lc="home" display="block" to="/">
            <Image
              alt="Jewlr"
              ratio="701 / 139"
              src={require("images/layout/logo/jewlr-logo.svg")}
              width={{ _: "101px", mobile: "139px" }}
            />
          </Link>
          <FlexRow justifyContent="space-between">
            <FlexRow>
              <HeaderIcon
                aria-label={loggedIn ? "My Account" : "Sign In"}
                as={Button}
                data-cy="menu-sign-in-mobile"
                onClick={() => toggleAccountMenu(showAccountMenu)}
                variant="text"
                {...(loggedIn && {
                  "aria-controls": "mobile-menu-account",
                  "aria-expanded": showAccountMenu,
                  "aria-haspopup": "true",
                })}
              >
                <NavIcon icon={faUser} />
              </HeaderIcon>

              <HeaderIcon>
                <WishlistIcon />
              </HeaderIcon>

              <HeaderIcon
                aria-label="Search"
                as={Button}
                data-cy="header-mobile-search-icon"
                onClick={(e) => toggleSearchBar(e)}
                ref={searchBtnRef}
                variant="text"
              >
                <NavIcon icon={faSearch} />
              </HeaderIcon>
              <HeaderIcon>
                <CartIcon isMobile />
              </HeaderIcon>
            </FlexRow>
            <BurgerMenu />
          </FlexRow>
        </MenuRow>
      )}
    </Header>
  )
}

MobileHeader.propTypes = {
  clearSearch: PropTypes.func,
  location: PropTypes.object,
  loggedIn: PropTypes.bool,
  showAccountMenu: PropTypes.bool,
  sticky: PropTypes.bool,
  toggleAccountMenu: PropTypes.func,
  toggleMenu: PropTypes.func,
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearSearch() {
      dispatch(clearSearchUserInput())
      dispatch(clearSearchSuggestions())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHeader(withRouter(MobileHeader)))
