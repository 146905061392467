import { Box, Flex, FontAwesomeIcon, Typography } from "@jewlr/storybook/core"
import styled from "styled-components"

export const StyledIcon = styled(FontAwesomeIcon).attrs((props) => ({
  color: "grey.30",
  fontSize: "15px",
  ...props,
}))``

export const GroupHeading = styled(Flex).attrs((props) => ({
  alignItems: "center",
  gap: "4px",
  mb: "6px",
  ...props,
}))``

export const GroupTitle = styled(Typography).attrs((props) => ({
  as: "h3",
  color: "grey.65",
  fontSize: "15px",
  fontWeight: "500",
  letterSpacing: "-0.02em",
  ...props,
}))``

export const ImageWrapper = styled(Box)`
  border: 1px solid ${(props) => props.theme.colors.grey[10]};
`

export const ProductName = styled(Typography).attrs({
  as: "h3",
})`
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 6px;
  text-align: center;
`
