import { Box, Flex, Typography } from "@jewlr/storybook/core"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const SuggestTitle = styled(Typography)`
  width: 100%;
  ${(props) => props.theme.search.suggestTitle}
`

export const SuggestionItem = styled(Flex).attrs({
  as: Link,
})`
  align-items: center;
  display: flex;
  ${(props) => props.theme.search.suggestionItem}
  &:focus {
    outline-offset: -2px;
  }
`

export const Subtitle = styled(Typography).attrs((props) => ({
  ...props.theme.search.subtitle,
}))``

export const StyledImage = styled(Box).attrs((props) => ({
  as: "img",
  height: "64px",
  mr: 2,
  width: "64px",
  ...props.theme.search.image,
}))``
