import React from "react"
import { connect } from "react-redux"
import { push, replace } from "connected-react-router"

export default function withHistory(WrappedComponent) {
  const InternalHistory = props => <WrappedComponent {...props} />

  const mapStateToProps = state => {
    return {
      router: state.router
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      push: url => {
        dispatch(push(url))
      },
      replace: url => {
        dispatch(replace(url))
      }
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(InternalHistory)
}
